import axios from '../../../api.service'

export const actions = {
  async fetchBrandingById(_, id) {
    const url = `/wholesale/brand/${id}`
    try {
      const res = await axios.get(url).then((res) => res.data)
      return res
    } catch (error) {
      // console.error(error, 'could not fetch branding')
    }
  },
}
