export default {
  SET_CURRENT_PAGE(state, currentPage) {
    try {
      currentPage.content.content = JSON.parse(currentPage.content.content)
    } catch {
      currentPage.content.content = []
    }
    state.currentPage = currentPage
  },
}
