<template>
  <div
    :style="{
      marginTop: props.marginTopValue + 'px',
      marginBottom: props.marginBottomValue + 'px',
      marginLeft: props.marginLeftValue + 'px',
      marginRight: props.marginRightValue + 'px',
    }"
  >
    <div class="imageContaner" :style="{ width: props.width, height: props.height }">
      <img
        v-lazy="'https://admin.tumar.com/' + props.src"
        alt="image"
      />
    </div>
    <div
      v-show="props.description || props.href"
      class="d-flex flex-column align-center"
    >
      <p
        v-show="props.description"
        class="text-center mb-3"
        v-html="props.description"
      ></p>
      <a
        v-show="props.buttonText"
        class="px-10 py-2"
        :href="props.href"
        :style="{
          color: props.buttonTextColor,
          backgroundColor: props.buttonColor,
        }"
      >
        {{ props.buttonText }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageComponent',
  props: {
    props: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style scoped>
img {
  height: 100%;
  width: 100%;
  display: block;
}
p {
  margin: 0 auto;
  width: 80%;
}
a {
  text-decoration: none;
  width: fit-content;
  background-color: rgb(197, 102, 29);
  border: 1px solid #e2e2e2;
  color: #fff !important;
  text-transform: capitalize;
}
.imageContaner {
  margin-bottom: 20px;
}
@media (max-width: 979px) {
  .imageContaner {
    height: auto !important;
    margin-bottom: 10px !important;
  } 
}

@media (max-width: 768px) {
  .container_mobile-v-craft .imageContaner {
    width: 100%!important
  }

}
</style>
