import axios from '@/core/services/api.service'

export default {
  fetchPriceList({ commit }, page) {
    return axios
      .get('/wholesale/price-list', {
        params: {
          page,
        },
      })
      .then((res) => {
        commit('SET_PRICE_LIST', res.data)
      })
  },
  fetchCatalog({ commit }) {
    return axios.get('/wholesale/catalog').then((res) => {
      commit('SET_CATALOG', res.data.data)
    })
  },
  fetchSelectedItem({ commit, state }, id) {
    return axios.get(`/wholesale/price-list/${id}`).then((res) => {
      commit('SET_SELECTED_ITEM', res.data)
    })
    // const selectedItem = getters.getItemById(id)
  },
}
