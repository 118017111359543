export default {
  toggleSideCart({ commit }, value) {
    commit('TOGGLE_SIDE_CART', value)
  },
  addCartItem({ commit }, item) {
    commit('ADD_CART_ITEM', item)
  },
  addSavedCartItems({ commit }, payload) {
    commit('ADD_SAVED_CART', payload)
  },
  removeCartItem({ commit }, cartId) {
    commit('REMOVE_CART_ITEM', cartId)
  },
  removeCartItemPosition({ commit }, { itemCartId, positionId }) {
    commit('REMOVE_CART_ITEM_POSITION', { itemCartId, positionId })
  },
  increaseCartItemCount({ commit }, { cartId, positionId }) {
    commit('CHANGE_CART_ITEM_COUNT', { cartId, positionId, increase: true })
  },
  decreaseCartItemCount({ commit }, { cartId, positionId }) {
    commit('CHANGE_CART_ITEM_COUNT', { cartId, positionId })
  },
}
