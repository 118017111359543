// USA
export const locale = {
  TRANSLATOR: {
    SELECT: 'Select your language',
  },
  MENU: {
    NEW: 'Новый',
    ACTIONS: 'Действия',
    CREATE_POST: 'Создать новый пост',
    PAGES: 'Страницы',
    FEATURES: 'Features',
    APPS: 'Приложения',
    DASHBOARD: 'Панель',
  },
  REYESTR_MENU: [
    {
      title: 'Сообщения',
      icon: '/img/icons/Group.svg',
      to: 'chats',
      disabled: false,
    },
    {
      title: 'Профиль',
      icon: '/img/icons/Document.svg',
      to: 'profile',
      disabled: false,
    },
    {
      title: 'Invoice',
      icon: '/img/icons/Paper.svg',
      to: 'invoice',
      disabled: false,
    },
    {
      title: 'История заказов',
      icon: '/img/icons/Folder.svg',
      disabled: false,
      to: 'order history',
      subLinks: [],
    },
    {
      title: 'История заказов розница',
      icon: '/img/icons/Folder.svg',
      disabled: false,
      to: 'order history retail',
      subLinks: [],
    },
    {
      title: 'Прайс лист',
      icon: '/img/icons/Group.svg',
      to: 'price list',
      disabled: false,
    },
    {
      title: 'Реестр',
      icon: '/img/icons/Group.svg',
      to: 'reyestr',
      disabled: false,
    },
  ],
  RETAIL_MENU: [
    {
      title: 'Сообщения',
      icon: '/img/icons/Group.svg',
      to: 'chats',
      disabled: false,
    },
    {
      title: 'Профиль',
      icon: '/img/icons/Document.svg',
      to: 'profile',
      disabled: false,
    },
    {
      title: 'История заказов розница',
      icon: '/img/icons/Folder.svg',
      disabled: false,
      to: 'order history retail',
      subLinks: [],
    },
  ],
  SEARCH: {
    SEARCH_KEYWORDS: 'Поиск по ключам...',
  },
  GROUPS: 'Группы',
  PRICE_TITLE: 'Цена',
  SELE_CTED_GROUP: 'Все',
  DROPDOWN: {
    items: ['Все', 'Мужчина', 'Женщина', 'Ребенок', 'Общий'],
  },
  FILTERS: {
    группы: {
      мужчина: false,
      женщина: false,
      ребенок: false,
      общий: false,
    },
    'техника производства': {
      'цельно-катаный': false,
      сшитый: false,
    },
    Модель: {
      тапочки: false,
      сапоги: false,
    },
  },
  FILTER_KEYS: {
    groups: 'группы',
    production_technique: 'техника производства',
    model: 'модель',
  },
  AUTH: {
    GENERAL: {
      OR: 'Or',
      SUBMIT_BUTTON: 'Submit',
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: 'Sign Up',
      FORGOT_BUTTON: 'Forgot Password',
      BACK_BUTTON: 'Back',
      PRIVACY: 'Privacy',
      LEGAL: 'Legal',
      CONTACT: 'Contact',
    },
    LOGIN: {
      TITLE: 'Login Account',
      BUTTON: 'Sign In',
    },
    FORGOT: {
      TITLE: 'Forgot Password?',
      DESC: 'Enter your email to reset your password',
      SUCCESS: 'Your account has been successfully reset.',
    },
    REGISTER: {
      TITLE: 'Sign Up',
      DESC: 'Enter your details to create your account',
      SUCCESS: 'Your account has been successfuly registered.',
    },
    LOGOUT: 'Выйти',
    INPUT: {
      EMAIL: 'Email',
      FULLNAME: 'Fullname',
      PASSWORD: 'Password',
      CONFIRM_PASSWORD: 'Confirm Password',
      USERNAME: 'Username',
    },
    VALIDATION: {
      INVALID: '{{name}} is not valid',
      REQUIRED: '{{name}} is required',
      MIN_LENGTH: '{{name}} minimum length is {{min}}',
      AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
      NOT_FOUND: 'The requested {{name}} is not found',
      INVALID_LOGIN: 'The login detail is incorrect',
      REQUIRED_FIELD: 'Required field',
      MIN_LENGTH_FIELD: 'Minimum field length:',
      MAX_LENGTH_FIELD: 'Maximum field length:',
      INVALID_FIELD: 'Field is not valid',
    },
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: 'Selected records count: ',
      ALL: 'All',
      SUSPENDED: 'Suspended',
      ACTIVE: 'Active',
      FILTER: 'Filter',
      BY_STATUS: 'by Status',
      BY_TYPE: 'by Type',
      BUSINESS: 'Business',
      INDIVIDUAL: 'Individual',
      SEARCH: 'Search',
      IN_ALL_FIELDS: 'in all fields',
    },
    ECOMMERCE: 'eCommerce',
    CUSTOMERS: {
      CUSTOMERS: 'Customers',
      CUSTOMERS_LIST: 'Customers list',
      NEW_CUSTOMER: 'New Customer',
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: 'Customer Delete',
        DESCRIPTION: 'Are you sure to permanently delete this customer?',
        WAIT_DESCRIPTION: 'Customer is deleting...',
        MESSAGE: 'Customer has been deleted',
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: 'Customers Delete',
        DESCRIPTION: 'Are you sure to permanently delete selected customers?',
        WAIT_DESCRIPTION: 'Customers are deleting...',
        MESSAGE: 'Selected customers have been deleted',
      },
      UPDATE_STATUS: {
        TITLE: 'Status has been updated for selected customers',
        MESSAGE: 'Selected customers status have successfully been updated',
      },
      EDIT: {
        UPDATE_MESSAGE: 'Customer has been updated',
        ADD_MESSAGE: 'Customer has been created',
      },
    },
  },
  HEADER: {
    GO_TO_STORE: 'Перейдите в магазин',
    CON_TACTS: 'Контакты',
  },
  MAIN: {
    h1: 'Добро пожаловать',
    tumar: 'Tumar',
    logout: 'Выйти',
    info: {
      1: 'С панели своего аккаунта ты можешь посмотреть',
      2: 'недавние заказы',
      3: 'управлять своми',
      4: 'адресами доставки и выставлением счетов',
      5: 'и',
      6: 'редактировать пороль и информацию об аккаунте.',
    },
  },
  PROFILE: {
    balance: 'Ваш баланс',
    order_details: 'Детали заказа',
    settings_title: 'Настройки',
    user_title: 'Пользователь',
    edit_user: 'Редактировать пользователя',
    user_form: ['Имя', 'Фамилия', 'Отображаемое имя', 'Email адрес', 'Роль', 'Пароль'],
    change_password: 'Изменить пароль',
    password_change_form: [
      'текущий пароль(оставьте пустым, чтобы оставить без изменений)',
      'новый пароль(оставьте пустым, чтобы оставить без изменений)',
      'подтвердите новый пароль',
    ],
    save_password: 'Сохранить изминения',
    add: 'Добавить',
    delete: 'удалить',
    edit: 'редактировать',
    user: ['Пользователь', 'E-mail adress', 'Роль', 'Действия', ''],
  },
  INVOICE: {
    order_number: 'Номер заказа',
    invoice_table: ['Номер инвойса', 'Дата', 'Статус', 'Итого', 'Сумма счета', 'Действия'],
    view: 'Просмотреть',
    status_value: 'Оброботка',
  },
  BRENDING: {
    price: 'Цена',
    choosePrice: 'Выберите цену',
    brending_text: 'Брендирование',
    pre_count: 'Количество выбранных элементов',
    colors: 'Цвета',
    add_to_cart: 'Добавить в корзину',
    size: 'Размер',
    brending_body: {
      place: 'Оставить',
      price_unchangable: 'Стоимость не изменна',
      price_changable: '  Стоимость будет меняться в зависимости от логотипа',
      replace: 'Заменить',
      delete: 'Удалить',
    },
  },
  PRODUCT_DESCRIPTION: {
    branding: 'Брендирование',
    table: {
      image: 'картинка',
      details: 'детали',
      price: 'цена',
      color: 'цвет',
      size: 'размер',
      count: 'колличество',
      total: 'итого',
      pre_count: 'преподсчет',
      order_details: 'детали заказа',
    },
    second_table: {
      totalCount: 'Общее кол-во',
      sum: 'сумма',
      discount: 'скидка',
      saving: 'экономия',
      delivery: 'доставка',
      total: 'итого',
      saved: 'сэкономлено',
    },
    go_to_order: 'перейти к оформлению',
    notes:
      'Детали нанесения логотипа на Ваш товар будут предоставлены позже по чату Менеджером',
    logo_place: 'место логотипа',
    order_details: 'Детальная информация о заказе',
  },
  REGISTRATION: {
    my_order: 'Мой заказ',
    payment_info: 'Информация о платяже',
    first_name: 'Имя',
    last_name: 'Фамилия',
    number: 'Номер',
    email: 'Email адрес',
    delivery_adress: 'Введите адресс доставки',
    country: 'Страна',
    countries: ['Россия', 'Украина', 'Кыргызстан', 'Казахстан', 'Беларусь'],
    address: 'Адрес',
    home_number: 'Номер дома и название улицы',
    locality: 'Населённый пункт',
    region: 'Область/регион',
    postcode: 'Почтовый индекс',
    inn: 'ИНН',
    your_order: 'Ваш заказ',
    coupon_code: 'ЕСЛИ У ВАС ЕСТЬ КОД-КУПОН, ПОЖАЛУЙСТА, ПРИМЕНИТЕ ЕГО НИЖЕ',
    coupon_placeholder: 'Есть промокод/номер дисконтной карты?',
    apply_coupon: 'Подтвердить купон',
    product: 'Товар',
    subtotal: 'Промежуточный итог',
    shipping: 'перевозки',
    total: 'итого',
    rule:
      'Размещая заказ, вы принимаете наши [get_rules], [get_politica_konf] и [get_return_and_exchange]. Вы также соглашаетесь с тем, что TUMAR будет хранить некоторые ваши данные, чтобы улучшить качество ваших услуг в следующий раз, когда вы будете делать покупки на нашем сайте.',
    place_order: 'разместить заказ',
  },
  ORDER_HISTORY: {
    order: 'Заказ',
    ordered: 'Заказано',
    shipped: 'Отправлено',
    expected_delivery: 'Ожидаемая доставка',
    order_number: 'Номер заказа',
    date: 'Дата',
    total: 'Всего',
    order_info: 'Информация о заказе',
    invoice: 'Инвойс',
    retry: 'Повторить',
    payment_terminal: 'Платежный терминал',
    delivery_address: 'Адрес доставки',
    order_table: ['Заказ', 'Дата', 'Статус', 'Итого', 'Действия'],
    statuses: [
      { value: 'В обработке', isActive: false },
      { value: 'В ожидании оплаты', isActive: false },
      { value: 'На производстве', isActive: false },
      { value: 'На удержании', isActive: false },
      { value: 'Выполнен', isActive: false },
      { value: 'Отменен', isActive: false },
      { value: 'Возвращен', isActive: false },
      { value: 'Не удался', isActive: false },
      { value: 'Упаковка товара', isActive: false },
      { value: 'Отправка товара', isActive: false },
      { value: 'В пути', isActive: false },
    ],
  },
  ORDER_COMPLEATE: {
    title: 'Перейти в историю заказов',
    thanksTitle: 'Спасибо. Ваш заказ был принят.',
  },
  PRICE_LIST: {
    price: 'Прайс лист',
    main: 'Главная',
  },
  CART_FOOTER: {
    view: 'Посмотреть корзину',
    ordering: 'Заказ',
    summary: 'Итого',
    basket: 'Корзина',
    close: 'закрыть',
  },
  ORDER_DETAILS: {
    account: 'Мой аккаунт',
    order_details: 'Детали заказа',
    title: 'ДЕТАЛИ ЗАКАЗА',
    table: ['Фото', 'Название', 'Остаток', 'Действия'],
    order_details_table: [
      '№',
      'Дата',
      'Название товара',
      'Колличество израсходованного брендирования',
      'Остаток на текущий момент',
    ],
  },
}
