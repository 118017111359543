export default {
  SET_BRAND_REMAINDER(state, amount) {
    state.doubt = amount
  },
  SET_ORDERS_DETAILS(state, data) {
    state.orderDetails = data
  },
  SET_ORDER_DETAILS(state, data) {
    state.orderDetail = data
  },
  SET_BRAND_COUNT(state, brands) {
    const bought_brands = JSON.parse(localStorage.getItem('bought_brands'))
    if (bought_brands?.length) {
      state.boughtBrands = bought_brands.map((boughtBrand) => {
        const foundBrand = brands.find((brand) => brand.id === boughtBrand.id)
        if (!foundBrand) {
          boughtBrand.remainder = foundBrand.remainder
          return boughtBrand
        }
        return { ...boughtBrand, status: 'added' }
      })
      localStorage.setItem('bought_brands', JSON.stringify(state.boughtBrands))
    } else {
      state.boughtBrands = brands.map((el) => {
        return {
          ...el,
          status: 'added',
        }
      })
      localStorage.setItem('bought_brands', JSON.stringify(state.boughtBrands))
    }
    // Добавляем новые бренды в массив
    // Сохраняем обновленный массив в localStorage
  },
  REMOVE_SAVED_BRANDS(state, brands) {
    const savedBrands = JSON.parse(localStorage.getItem('bought_brands'))
    if (savedBrands.length !== 0) {
      brands?.forEach((item) => {
        savedBrands?.forEach((brand) => {
          if (brand.id === item.id) {
            brand.remainder =
              item.isEnough === 'not enough' &&
              brand.remainder + item.selected_count >= +item.count
                ? brand.remainder + item.selected_count - +item.count
                : item.isEnough === 'enough' &&
                  brand.remainder + item.selected_count >= +item.count
                ? brand.remainder + item.selected_count - +item.count
                : brand.remainder + item.selected_count
            brand.selected_count = brand.selected_count - item.selected_count
            // brand.boughtBrandsKit =
            //   item.isEnough === 'not enough' &&
            //   brand.remainder + item.selected_count >= +item.count
            //     ? brand.boughtBrandsKit - 1
            //     : item.isEnough === 'enough' &&
            //       brand.remainder + item.selected_count >= +item.count
            //     ? boughtBrandsKit - 1
            //     : item.isEnough === 'enough'
            //     ? 0
            //     : brand.boughtBrandsKit - 1
          }
        })
      })
      localStorage.setItem('bought_brands', JSON.stringify(savedBrands))
    }
  },
}

// if (bought_brands) {
//   brands.forEach((item) => {
//     const existingItem = bought_brands.find((el) => el.id === item.id)
//     item.reminder = +existingItem?.reminder - +item?.selected_count
//   })
// }

360

360 + 2000

2360 - 1000

1360

1360 - 1000

360
