var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"margin":"0 auto"},style:({
  marginBottom: _vm.showMargin ? _vm.props.styles.marginBottom + 'px' : 'auto',
  marginTop: _vm.showMargin ? _vm.props.styles.marginTop + 'px' : 'auto',
  marginLeft: _vm.showMargin ? _vm.props.styles.marginLeft + 'px' : 'auto',
  marginRight: _vm.showMargin ? _vm.props.styles.marginRight + 'px' : 'auto',
  borderLeftWidth: _vm.props.styles.borderLeftWidth + 'px',
  borderTopWidth: _vm.props.styles.borderTopWidth + 'px',
  borderBottomWidth: _vm.props.styles.borderBottomWidth + 'px',
  borderRightWidth: _vm.props.styles.borderRightWidth + 'px',
  borderColor: _vm.props.styles.borderColor,
  borderStyle: _vm.props.styles.borderStyle,
  
})},[_c('v-row',{staticClass:"ma-0 d-flex flex-column",class:_vm.props.alignment,style:({
    paddingBottom: _vm.showPadding ? _vm.props.styles.paddingBottom + 'px' : 'auto',
    paddingTop: _vm.showPadding ? _vm.props.styles.paddingTop + 'px' : 'auto',
    paddingLeft: _vm.showPadding ? _vm.props.styles.paddingLeft + 'px' : 'auto',
    paddingRight: _vm.showPadding ? _vm.props.styles.paddingRight + 'px' : 'auto',
    '@media (max-width: 768px)': {
      marginLeft: _vm.showMargin ? _vm.props.styles.mobileLeft + 'px' : 'auto',
      marginRight: _vm.showMargin ? _vm.props.styles.mobileRight + 'px' : 'auto',
      marginTop: _vm.showMargin ? _vm.props.styles.mobileTop + 'px' : 'auto',
      marginBottom: _vm.showMargin ? _vm.props.styles.mobileBottom + 'px' : 'auto',
      paddingLeft: _vm.showPadding ? _vm.props.styles.mobilePaddingLeft + 'px' : 'auto',
      paddingRight: _vm.showPadding ? _vm.props.styles.mobilePaddingRight + 'px' : 'auto',
      paddingTop: _vm.showPadding ? _vm.props.styles.mobilePaddingTop + 'px' : 'auto',
      paddingBottom: _vm.showPadding ? _vm.props.styles.mobilePaddingBottom + 'px' : 'auto',
    },

  })},[(_vm.children.length)?_vm._l((_vm.children),function(ch,index){return _c('recursion',{key:index,attrs:{"content":ch,"componentName":ch.props.component || ch.componentName}})}):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }