export default {
  ADD_MESSAGE(state, { chatId, message }) {
    const chat = state.messages.find((messages) => messages.chatId == chatId)
    chat.messages.push(message)
  },
  GET_MESSAGES(state, data) {
    state.nextPage = data.next_page_url
    state.messages = data.data.reverse()
  },
  LOAD_MORE_MESSAGES(state, messages) {
    state.messages.unshift(...messages?.reverse())
  },
  SCROLL_TO_NEW_MESSAGES(state, obj) {
    if (obj?.data) {
      state.isScrollTop = !state.isScrollTop
    }
  },
  SET_CHAT_INFO(state, chat) {
    state.chatInfo = chat
  },
  ADD_NEW_MESSAGE(state, message) {
    state.messages.push(message)
  },
  SET_NEXT_PAGE(state, next_page_url) {
    state.nextPage = next_page_url
  },
  RESET_NEXT_PAGE(state) {
    state.nextPage = ''
  },
  SET_MEDIA_MESSAGES(state, data) {
    state.media = data.data
    state.fileMessages = data?.data?.filter(
      (i) =>
        i.file_format !== 'jpeg' ||
        i.file_format !== 'jpg' ||
        i.file_format !== 'png'
    )
  },
  SET_MARKED_MESSAGES(state, data) {
    state.markedMessages = [...state.markedMessages, ...data.data]
  },
  SET_READED_MESSAGES(state, messages) {
    messages.forEach((el) => {
      state.messages = state.messages.map((item) => {
        if (item?.id === el) {
          return {
            ...item,
            is_read: true,
          }
        } else return item
      })
    })
  },
  SET_MESSAGE_MARK(state, color) {
    state.messages = state.messages.map((mark) => {
      if (mark.id === color.id) {
        return {
          ...mark,
          mark: color.color,
        }
      } else return mark
    })
    state.messages.forEach((mark) => {
      if (mark.id === color.id) {
        const existed = state.markedMessages.find((el) => el.id === mark.id)
        if (existed) {
          state.markedMessages = state.markedMessages.map((el) => {
            if (mark.id === el.id) {
              return {
                ...mark,
                mark: color.color,
              }
            } else return el
          })
        } else {
          state.markedMessages.push({
            ...mark,
            mark: color.color,
          })
        }
      }
    })
  },
  SET_MEDIA_MESSAGE(state, message) {
    if (message.file_format) {
      state.media = [...state.media, message]
    }
  },
}
