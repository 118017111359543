import axios from '@/core/services/api.service'

export default {
  searchProducts({ commit }, text) {
    return axios.post('/frontend/search/product', { text }).then((res) => {
      commit('SET_PRODUCTS', res.data.data)
    })
  },
  getProductBySlug({ dispatch, commit }, { slug }) {
    return axios.get(`/frontend/product/${slug}`).then((res) => {
      commit('SET_PRODUCT', res?.data)
    })
  },
  getAllProducts({ commit }) {
    return axios.get('/frontend/product').then((res) => {
      commit('SET_ALL_PRODUCTS', res.data.data)
    })
  },
  productByCatigory({ commit }, { id }) {
    return axios.get(`/frontend/product/category/${id}`).then((res) => {
      commit('SET_PRODUCTS', res.data)
    })
  },
  async productByCatigories({ commit }, { id }) {
    const res = await axios.get(`/frontend/product/category/${id}`)
    
    return res.data.data
  },
  fetchProductsPage({ commit }, { id, url }) {
    return axios.get(`/frontend/product/category/${id}${url}`).then((res) => {
      commit('SET_PRODUCTS', res.data)
    })
  },
  getProductsMainPage({ commit }) {
    return axios.get('/frontend/product/main').then((res) => {
      commit('SET_MAIN_PRODUCTS', res.data.data)
    })
  },
  getReviews({ commit }, { productId }) {
    return axios.get(`/frontend/review/product/${productId}`).then((res) => {
      commit('SET_REVIEWS', res.data.data)
    })
  },
  postReviews({ commit }, review) {
    return axios.post(`/frontend/review/create`, review, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      console.log(res);
      
    })
  },
}
