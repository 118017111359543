import axios from '@/core/services/api.service'

export const actions = {
  fetchFAQs({ commit, rootState }) {
    const api = `/frontend/faq`
    return axios.get(api).then((res) => {
      commit('SET_FAQs', res.data)
    })
  },
  createFAQ(data, question) {
    const api = `/frontend/faq`
    return axios.post(api, { question: question })
  }
}
