<template>
  <div
    class="plainImage"
    style="min-width: 50px; background-position-x: top; background-position-y: center; "
    :style="{ ...props.styles, backgroundImage: `url('${'https://admin.tumar.com/' + props.src}')` }"
  />
</template>

<script>
export default {
  name: 'PlainImage',
  props: {
    props: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style scoped>
 @media (max-width: 979px) {
  .plainImage {
    height: 250px !important;
    min-height: 250px !important;
    width: auto !important;
    background-attachment: unset !important;
    background-position: center !important;
  }
 } 
</style>
