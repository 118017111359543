<template>
  <div :style="props" :class="props.classes"> </div>
</template>

<script>
export default {
  name: 'BuilderDivider',
  props: {
    props: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style></style>
