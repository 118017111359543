import axios from '@/core/services/api.service'

export const actions = {
  async fetchFooterMenus({ commit, rootState }) {
    const localeLang = rootState?.auth?.localeLang

    const api = `/frontend/footer-menu?locale=${localeLang}`
    return await axios.post(api).then((res) => {
      commit('SET_FOOTER_MENUS', { payload: res.data })
    })
  },
}
