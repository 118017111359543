var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isDesktop)?_c('v-col',{attrs:{"cols":_vm.content.props.defaultWidth,"sm":_vm.content.props.mobileWidth,"md":_vm.content.props.tabletWidth,"lg":_vm.content.props.desktopWidth}},[_c('div',{staticClass:"cop-testCol",class:['d-flex flex-column', this.content.props.contentPosition, _vm.content.props.flex],staticStyle:{"background-position":"center","background-size":"cover","background-repeat":"no-repeat"},style:({ 
      backgroundImage: `url('${'https://admin.tumar.com/' + _vm.content.props.backgroundImage}')`, 
    })},[(_vm.children.length)?_vm._l((_vm.children),function(ch,index){return _c('recursion',{key:index,attrs:{"content":ch,"componentName":ch.props.component ? ch.props.component : ch.componentName}})}):_vm._e()],2)]):_c('v-col',{style:({ 
            ..._vm.content.props.styles,
            padding: `${_vm.content.props.mobilePaddingTop}px 
                      ${_vm.content.props.mobilePaddingRight}px 
                      ${_vm.content.props.mobilePaddingBottom}px 
                      ${_vm.content.props.mobilePaddingLeft}px !important`,
          }),attrs:{"cols":_vm.content.props.defaultWidth,"sm":_vm.content.props.mobileWidth,"md":_vm.content.props.tabletWidth,"lg":_vm.content.props.desktopWidth}},[_c('div',{staticClass:"cop-testCol",class:['d-flex!important flex-column!important', this.content.props.contentPosition, _vm.content.props.flex],staticStyle:{"background-position":"center","background-size":"cover","background-repeat":"no-repeat"},style:({ 
      backgroundImage: `url('${'https://admin.tumar.com/' + _vm.content.props.backgroundImage}')`, 
    })},[(_vm.children.length)?_vm._l((_vm.children),function(ch,index){return _c('recursion',{key:index,attrs:{"content":ch,"componentName":ch.props.component ? ch.props.component : ch.componentName}})}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }