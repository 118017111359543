<template>
  <div class="list_items">
    <div class="p_img">
      <img
        :src="'https://admin.tumar.com' + product.photo"
        alt="Photo product"
      />
      <button class="add_to_cart" @click.stop="$emit('goToDetails', product)">
        ADD TO CART
      </button>
      <!-- <span class="sale">Sale</span> -->
    </div>
    <div class="p_text">
      <p>{{ product.name.ru }}</p>
      <div class="price">
        <div
          class="new_price notranslate"
          :class="+price?.discount.view ? 'old_price' : 'new_price'"
        >
          <span>{{ price?.view }}</span>
          <span>{{ currency?.currency }}</span>
        </div>
        <div v-show="+price?.discount?.view" class="new_price notranslate">
          <span>{{ productPrice(price?.view, price?.discount?.view) }}</span>
          <span>{{ currency?.currency }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: State_Auth } = createNamespacedHelpers('auth')

export default {
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    price: {
      type: Object,
    },
    currency: {
      type: Object,
    },
  },
  computed: {
    ...State_Auth(['localeLang']),
  },
  methods: {
    productPrice(price, discount) {
      return Number(price - discount).toFixed(2)
    },
  },
}
</script>

<style>
.new_price span {
  font-weight: 700;
}
.list_items {
  height: 100%;
}
</style>
