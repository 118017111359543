import axios from '@/core/services/api.service'

export default {
  setSelectedProduct({ commit }, id) {
    commit('SET_SELECTED_PRODUCT', id)
  },
  setSelectedGroup({ commit }, val) {
    commit('SET_SELECTED_GROUP', val)
  },
  fetchReyestrItems({ commit }, categoryValue, page) {
    return axios
      .get(`/wholesale/catalog`, {
        params: {
          category: categoryValue,
          page,
        },
      })
      .then((res) => {
        commit('SET_REYESTR_ITEMS', res.data)
      })
  },
  fetchReyestrItemById({ commit }, id) {
    return axios.get(`/wholesale/catalog/${id}`).then((res) => {
      commit('SET_SELECTED_REYESTR_ITEM', res.data)
    })
  },
  fetchProductCategories({ commit }) {
    return axios.get(`wholesale/category`).then((res) => {
      commit('SET_PRODUCT_CATEGORIES', res.data)
    })
  },
}
