<template>
  <v-expansion-panels
    v-model="expansion"
    accordion
    flat
    class="aboutUs__accordion"
  >
    <!-- <slot /> -->
    <AccordionItemVue
      v-for="(ch, index) in children"
      :key="index"
      :title="ch.props.title"
      :index="index"
      :children="ch.children"
      :expansion="expansion"
    />
  </v-expansion-panels>
</template>

<script>
import AccordionItemVue from './AccordionItem.vue'
export default {
  name: 'Accordion',
  props: {
    props: {
      type: Object,
      default: () => {},
    },
    children: {
      type: Array,
      default: () => {},
    },
  },
  components: {
    AccordionItemVue,
  },
  data() {
    return {
      expansion: 0,
    }
  },
}
</script>

<style scoped>
.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: unset;
}
.accordion-btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 17px 0px;
  cursor: pointer;
}
.accordion-btn h2 {
  width: 80%;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #666666;
  transition: background-color 0.2s linear;
  margin: 0;
}
.accordion-icon {
  background-repeat: no-repeat;
  width: 23px;
  height: 23px;
  display: inline-block;
  margin-right: 16px;
  position: relative;
}
.aboutUs__accordion {
  border-radius: unset;
}
</style>
