import axios from '@/core/services/api.service'

export const actions = {
  async fetchBlogs({ commit, rootState }, { category }) {
    // params = {
    //   order_by: undefined,
    //   sort: undefined,
    //   limit: undefined,
    //   category_id: undefined,
    // }
    const id = category?.id || 1
    const localeLang = rootState.auth.localeLang
    // const {
    //   order_by = undefined,
    //   sort = undefined,
    //   limit = undefined,
    //   category_id = undefined,
    // } = params
    const api = `frontend/blog?locale=${localeLang}`
    // { params: { order_by, sort, limit, category_id } }
    return await axios.get(api, { params: { category_id: id } }).then((res) => {
      if (category?.url === 'collections') {
        commit('SET_COLLECTION_BLOGS', res.data.data)
      } else {
        commit('SET_BLOGS', res.data.data)
      }
    })
  },
  async fetchBlogByUrl({ rootState }, url) {
    const localeLang = rootState.auth.localeLang

    const api = `frontend/blog/${url}?locale=${localeLang}` // возвращает русский вариант вне зависимости от параметра
    return axios.get(api).then((res) => {
      return res.data
    })
  },
}
