var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"FAQ"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"tabs d-flex row ma-0"},[_c('div',{staticClass:"tabs__links col-4 pa-0",style:(_vm.$vuetify.breakpoint.smAndDown ? 'overflow: auto;' : '')},[_c('div',{staticClass:"tab__link",class:{
            active: _vm.tab === 0,
            'active-mobile': _vm.$vuetify.breakpoint.smAndDown && _vm.tab === 0,
            'd-flex justify-center': _vm.$vuetify.breakpoint.smAndDown,
          },style:(_vm.$vuetify.breakpoint.smAndDown ? 'min-width: 125px;' : ''),on:{"click":function($event){_vm.tab = 0}}},[_vm._m(0),_vm._m(1)]),_c('div',{staticClass:"tab__link",class:{
            active: _vm.tab === 1,
            'active-mobile': _vm.$vuetify.breakpoint.smAndDown && _vm.tab === 1,
            'd-flex justify-center': _vm.$vuetify.breakpoint.smAndDown,
          },style:(_vm.$vuetify.breakpoint.smAndDown ? 'min-width: 125px;' : ''),on:{"click":function($event){_vm.tab = 1}}},[_vm._m(2),_c('p',[_vm._v("Checkout")])]),_c('div',{staticClass:"tab__link",class:{
            active: _vm.tab === 2,
            'active-mobile': _vm.$vuetify.breakpoint.smAndDown && _vm.tab === 2,
            'd-flex justify-center': _vm.$vuetify.breakpoint.smAndDown,
          },style:(_vm.$vuetify.breakpoint.smAndDown ? 'min-width: 125px;' : ''),on:{"click":function($event){_vm.tab = 2}}},[_vm._m(3),_c('p',[_vm._v("Delivery")])]),_c('div',{staticClass:"tab__link",class:{
            active: _vm.tab === 3,
            'active-mobile': _vm.$vuetify.breakpoint.smAndDown && _vm.tab === 3,
            'd-flex justify-center': _vm.$vuetify.breakpoint.smAndDown,
          },style:(_vm.$vuetify.breakpoint.smAndDown ? 'min-width: 125px;' : ''),on:{"click":function($event){_vm.tab = 3}}},[_vm._m(4),_vm._m(5)]),_c('div',{class:{
            'active active--contact': _vm.tab === 4,
            'd-flex justify-center mr-0 px-3': _vm.$vuetify.breakpoint.smAndDown,
          },style:(_vm.$vuetify.breakpoint.smAndDown ? 'min-width: 125px;' : ''),on:{"click":function($event){_vm.tab = 4}}},[_c('div',{staticClass:"tabs__links-img",class:{ 'd-none': _vm.$vuetify.breakpoint.smAndDown }}),_c('p',{staticClass:"ma-0"},[_vm._v("Contact us")])])]),_c('div',{staticClass:"col-8 tabs__kontent"},[(_vm.children.length)?_vm._l((_vm.children),function(ch,index){return _c('recursion',{key:index,attrs:{"content":ch,"componentName":ch.props.component ? ch.props.component : ch.componentName}})}):_vm._e()],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs__links-img"},[_c('img',{attrs:{"src":"/img/productInfo.png","alt":"productInfo.png","width":"68","height":"25"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Product"),_c('br'),_vm._v("information")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs__links-img"},[_c('img',{attrs:{"src":"/img/checkout.png","alt":"checkout.png","width":"81","height":"51"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs__links-img"},[_c('img',{attrs:{"src":"/img/delivery.png","alt":"delivery.png","width":"67","height":"61"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs__links-img"},[_c('img',{attrs:{"src":"/img/return.png","alt":"return.png","width":"40","height":"51"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Return"),_c('br'),_vm._v("policy")])
}]

export { render, staticRenderFns }