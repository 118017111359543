import feltShoes from '@/core/mock/feltShoes.json'

export default {
  shoes: feltShoes,
  selectedProduct: null,
  selectedGroup: 'Все',
  reyestrItems: [],
  selectedReyestrItem: null,
  productCategory: [],
}
