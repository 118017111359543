<template>
  <v-carousel
    :interval="props.interval"
    cycle
    style="position: relative"
    class="slider carousel"
    hide-delimiters
    :show-arrows="false"
    :height="props.height"
    v-model="activeCarouselItem"
    :style="{
      width: props.width,
      height: props.height,
      minHeight: props.height,
      marginTop: props.marginTop + 'px',
      marginBottom: props.marginBottom + 'px',
      marginLeft: props.marginLeft + 'px',
      marginRight: props.marginRight + 'px',
    }"
  >
    <v-carousel-item
      v-for="item in props.items"
      :key="item.id"
      :src="'https://admin.tumar.com/' + item.image"
    >
      <div
        :style="{
          width: item.width,
          fontSize: item.fontSize + 'px',
          fontWeight: item.fontWeight,
          alignSelf: item.alignSelf,
          background: 'https://admin.tumar.com/' + item.image,
        }"
        class="buttons-container"
      >
        <p v-html="item?.content" :style="{ color: '#FFFFFF' }"></p>
        <div v-for="(itemButton, key) in item?.buttons" :key="key">
          <a :href="itemButton.href">
            <button
              :style="{
                width: itemButton.width,
                height: itemButton.height,
                color: itemButton.buttonTextColor,
                fontSize: itemButton.fontSize + 'px',
                fontWeight: itemButton.fontWeight,
                alignSelf: itemButton.alignSelf,
                background: itemButton.buttonColor,
              }"
              >{{ itemButton.textButton }} button</button
            >
          </a>
        </div>
      </div>
    </v-carousel-item>

    <!-- Стрелка влево -->
    <!-- <img
      src="https://cdn.icon-icons.com/icons2/933/PNG/512/keyboard-left-arrow-button_icon-icons.com_72692.png"
      alt="Left Arrow"
      class="custom-arrow left-arrow"
      @click="prevSlide"
    /> -->

    <!-- Стрелка вправо -->
    <!-- <img
      src="https://cdn.icon-icons.com/icons2/933/PNG/512/keyboard-right-arrow-button-1_icon-icons.com_72690.png"
      alt="Right Arrow"
      class="custom-arrow right-arrow"
      @click="nextSlide"
    /> -->

    <div class="slider_dots">
      <ul class="pa-0">
        <li
          v-for="(item, index) in props.items.length"
          :key="index"
          class="dots"
          :class="{ dots_active: index === activeCarouselItem }"
          @click="activeCarouselItem = index"
        ></li>
      </ul>
    </div>
  </v-carousel>
</template>

<script>
export default {
  props: {
    props: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      activeCarouselItem: 0,
    }
  },
  methods: {
    prevSlide() {
      this.activeCarouselItem =
        (this.activeCarouselItem - 1 + this.props.items.length) %
        this.props.items.length
    },
    nextSlide() {
      this.activeCarouselItem =
        (this.activeCarouselItem + 1) % this.props.items.length
    },
  },
}
</script>

<style scoped>
.custom-arrow {
  position: absolute;
  bottom: 25px;
  transform: translateY(-50%);
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

.slider_dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.dots {
  width: 13px;
  height: 10px;
  border-radius: 50%;
  background: #bbb;
  display: inline-block;
  margin-right: 5px;
  cursor: pointer;
}

.dots_active {
  background: #ffffff;
}

.slider_dots ul {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  width: fit-content;
}

/* hardcoded style because did not get the following style in response from back */
/* .buttons-container {
  margin-top: 150px;
  margin-left: 200px;
  @media (max-width: 576px) {
    margin-top: 270px;
    margin-left: 10px;
  }
} */

.buttons-container {
  position: absolute;
  bottom: 71px;
  left: 71px;
}

.buttons-container > p > p:first-child *,
.buttons-container > p > p:first-child {
  font-size: 63px;
  color: white;
}

.slider.carousel p h1 {
  font-size: 65px;
  color: white;
  margin-bottom: 29px;
  font-weight: bold;
}
.slider.carousel p,
.slider.carousel p h1 ~ p,
.slider.carousel p strong {
  color: white;
  font-size: 16px;
  display: inline-block;
}
.slider.carousel p h1 ~ p {
  height: 60px;
}

.slider.carousel button {
  margin-top: 3px;
}

@media (max-width: 844px) {
  .slider.carousel .buttons-container {
    width: 84% !important;
  }
  .slider.carousel .buttons-container {
    left: 35px;
  }
  .slider.carousel p h1 {
    font-size: 51px;
    margin-bottom: 15px;
    font-weight: 700;
    line-height: 40px;
  }
  .slider.carousel button {
    margin-top: 15px;
    padding: 0px 15px;
  }
}
</style>
