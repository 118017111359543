import axios from '@/core/services/api.service'
import cloneDeep from 'lodash.clonedeep'
export const actions = {
  async fetchAllUsers({ state, commit }) {
    return axios.get('/wholesale/employee').then((res) => {
      commit('SET_EMPLOYEES', res.data.data)
    })
  },

  async deleteEmployee({ state, commit }, id) {
    const api = `/wholesale/employee/${id}`
    return axios.delete(api).then(() => {
      commit(
        'SET_EMPLOYEES',
        state.employees.filter((e) => e.id !== id)
      )
    })
  },

  async createEmployee({ state, commit }, body) {
    const api = '/wholesale/employee'
    const updatedEmployees = cloneDeep(state.employees)
    return axios.post(api, body).then((res) => {
      const newEmployee = res.data
      updatedEmployees.push(newEmployee)
      commit('SET_EMPLOYEES', updatedEmployees)
    })
  },

  async updateEmployee({ dispatch }, { body, id }) {
    const api = `/wholesale/employee/${id}`
    return axios.put(api, body).then((res) => {
      dispatch('fetchAllUsers')
    })
  },
}
