<template>
  <div v-show="activeTab === props.tab" :style="props" :class="props.classes">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BuilderNestedContainer',
  inject: {
    tab: { default: 0 },
  },
  props: {
    props: {
      type: Object,
      default: () => {},
    },
    children: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    activeTab() {
      return this.tab()
    },
  },
}
</script>

<style lang="scss" scoped>
.tab {
  &:not(:last-child) {
    border-right: 1px solid #e6e6e6;
  }
  color: #9a9a9a !important;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.07em;
  &--active {
    color: #423f3d !important;
  }
}
</style>
