<template>
  <v-col
    v-if="isDesktop"
    :cols="content.props.defaultWidth"
    :sm="content.props.mobileWidth"
    :md="content.props.tabletWidth"
    :lg="content.props.desktopWidth"
  >
    <div 
      class="cop-testCol"
      :class="['d-flex flex-column', this.content.props.contentPosition, content.props.flex]"
      :style="{ 
        backgroundImage: `url('${'https://admin.tumar.com/' + content.props.backgroundImage}')`, 
      }"
      style="
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      "
    >
      <template v-if="children.length">
        <recursion
          v-for="(ch, index) in children"
          :key="index"
          :content="ch"
          :componentName="
            ch.props.component ? ch.props.component : ch.componentName
          "
        />
      </template>
    </div>
  </v-col>
  <v-col
    v-else
    :cols="content.props.defaultWidth"
    :sm="content.props.mobileWidth"
    :md="content.props.tabletWidth"
    :lg="content.props.desktopWidth"
    :style="{ 
              ...content.props.styles,
              padding: `${content.props.mobilePaddingTop}px 
                        ${content.props.mobilePaddingRight}px 
                        ${content.props.mobilePaddingBottom}px 
                        ${content.props.mobilePaddingLeft}px !important`,
            }"
  >
    <div 
      class="cop-testCol"
      :class="['d-flex!important flex-column!important', this.content.props.contentPosition, content.props.flex]"
      :style="{ 
        backgroundImage: `url('${'https://admin.tumar.com/' + content.props.backgroundImage}')`, 
      }"
      style="
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      "
    >
      <template v-if="children.length">
        <recursion
          v-for="(ch, index) in children"
          :key="index"
          :content="ch"
          :componentName="
            ch.props.component ? ch.props.component : ch.componentName
          "
        />
      </template>
    </div>
  </v-col>
</template>

<script>
export default {
  props: ['children', 'content'],
  data() {
    return {
      isDesktop: window.innerWidth > 780,
    };
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isDesktop = window.innerWidth > 780;
    },
  },
}
</script>

<style scoped>
.crafted-component container-v-craft {
  display: flex !important;
  flex-direction: column !important;
}
@media (max-width: 980px) {
    .crafted-component, .crafted-component *:not(>a) {
        width: 100%!important;
        height: auto!important;
    }

    .crafted-component.col-sm-4.col-md-3.col-lg-12 {
        padding: 10px!important;
    }
    .crafted-component.col-12:not() {
      /* padding: 10px!important; */
      border-width: 0px 0px 0px 0px !important;
    }
    .cop-testCol .flex-center > .crafted-component {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
    }

    .cop-testCol .flex-center > .crafted-component .d-flex.flex-column.align-center {
        width: 100%;
    }
}
@media (max-width: 730px) {
  .cop-testCol > .crafted-component{
    width: 100% !important;
  }
}
.flex-center {
  display: flex;
  justify-content: center;
}
.flex-right {
  display: flex;
  justify-content: right;
}
.flex-left {
  display: flex;
  justify-content: left;
}
</style>
