var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.props.href)?_c('div',{staticClass:"icon-content d-flex",class:{
    'flex-column': _vm.props.contentAlign === 'bottom',
    'flex-row-reverse': _vm.props.contentAlign === 'left',
  },style:({ textAlign: _vm.props.textAlign, 
    alignItems: _vm.props.contentAlign === 'right' && _vm.props.title ? 'baseline' : 'normal', })},[_c('a',{staticClass:"icon-content__img",class:[
        _vm.props.contentAlign === 'bottom'
          ? 'align-self-' + _vm.props.imageAlign
          : '',
      ],style:({
        height: _vm.props.imageHeight,
        maxWidth: _vm.props.imageWidth,
        minWidth: _vm.props.imageWidth,
        marginRight: _vm.props.contentAlign === 'right' && _vm.props.title ? _vm.props.imagePaddingRight : '0',
        marginBottom:
          _vm.props.contentAlign === 'bottom' && _vm.props.title ? _vm.props.imagePadding || '18px' : '0',
      }),attrs:{"href":_vm.props.href}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('https://admin.tumar.com/' + _vm.props.imgSrc),expression:"'https://admin.tumar.com/' + props.imgSrc"}],attrs:{"alt":"icon"}})]),_c('div',{staticClass:"d-flex flex-column"},[(_vm.props.title)?_c('a',{staticClass:"icon-content__title",class:{ 'no-events': !_vm.props.href },style:({paddingBottom: _vm.props.titlePadding, color: _vm.props.titleColor}),attrs:{"href":_vm.props.href},domProps:{"innerHTML":_vm._s(_vm.props.title)}}):_vm._e(),(_vm.isDesktop || !_vm.props.textHeight)?_c('a',{staticClass:"icon-content__text",class:{ 'no-events': !_vm.props.href },style:({color: _vm.props.textColor}),attrs:{"href":_vm.props.href},domProps:{"innerHTML":_vm._s(_vm.props.text)}}):_c('a',{staticClass:"icon-content__text",class:{ 'no-events': !_vm.props.href },style:({ height: _vm.props.textHeight, color: _vm.props.textColor }),attrs:{"href":_vm.props.href},domProps:{"innerHTML":_vm._s(_vm.props.text)}}),(_vm.props.href)?_c('a',{staticClass:"icon-content__link",class:{ 'no-events': !_vm.props.href },style:({color: _vm.props.linkColor}),attrs:{"href":_vm.props.href}},[_vm._v(" "+_vm._s(_vm.props.buttonText)+" ")]):_vm._e()])]):_c('div',{staticClass:"icon-content d-flex",class:{
      'flex-column': _vm.props.contentAlign === 'bottom',
      'flex-row-reverse': _vm.props.contentAlign === 'left',
    },style:({ textAlign: _vm.props.textAlign, 
      alignItems: _vm.props.contentAlign === 'right' && _vm.props.title ? 'baseline' : 'normal', })},[_c('div',{staticClass:"icon-content__img",class:[
        _vm.props.contentAlign === 'bottom'
          ? 'align-self-' + _vm.props.imageAlign
          : '',
      ],style:({
        height: _vm.props.imageHeight,
        maxWidth: _vm.props.imageWidth,
        minWidth: _vm.props.imageWidth,
        marginRight: _vm.props.contentAlign === 'right' && _vm.props.title ? _vm.props.imagePaddingRight : '0',
        marginBottom:
          _vm.props.contentAlign === 'bottom' && _vm.props.title ? _vm.props.imagePadding || '18px' : '0',
      })},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('https://admin.tumar.com/' + _vm.props.imgSrc),expression:"'https://admin.tumar.com/' + props.imgSrc"}],attrs:{"alt":"icon"}})]),_c('div',{staticClass:"d-flex flex-column"},[(_vm.props.title)?_c('p',{staticClass:"icon-content__title",style:({paddingBottom: _vm.props.titlePadding, color: _vm.props.titleColor}),domProps:{"innerHTML":_vm._s(_vm.props.title)}}):_vm._e(),(_vm.isDesktop || !_vm.props.textHeight)?_c('p',{staticClass:"icon-content__text",style:({color: _vm.props.textColor}),domProps:{"innerHTML":_vm._s(_vm.props.text)}}):_c('p',{staticClass:"icon-content__text",style:({ height: _vm.props.textHeight, color: _vm.props.textColor }),domProps:{"innerHTML":_vm._s(_vm.props.text)}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }