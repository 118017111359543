export default {
  // getMessagesByChatId: (state) => (chatId) => {
  //   let messages = JSON.parse(JSON.stringify(state.messages))
  //   let currentMessages = messages.find((messages) => messages.chatId == chatId)
  //   currentMessages.messages.forEach((msg) => {
  //     msg.showAvatar = true
  //     msg.showTime = true
  //     if (msg.me) {
  //       msg.showAvatar = false
  //     }
  //   })
  //   let i = 0
  //   for (let j = 1; j < currentMessages.messages.length; j++) {
  //     if (
  //       currentMessages.messages[i].authorId !==
  //       currentMessages.messages[j].authorId
  //     ) {
  //       i++
  //     } else {
  //       currentMessages.messages[i].showAvatar = false
  //       currentMessages.messages[i].showTime = false
  //       currentMessages.messages[j].showAvatar = true
  //       currentMessages.messages[j].showTime = true
  //       i++
  //     }
  //   }
  //   return currentMessages
  // },
}
