<template>
  <div class="paragraph" :style="{fontSize: props.fontSize + 'px', ...props.styles, display: 'block'}" v-html="props.content"></div>
</template>

<script>
export default {
  name: 'BuilderParagraph',
  props: {
    props: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style module>
.paragraph span {
  font-size: 1em;
}
.paragraph p {
  font-size: 1em !important;
}
.paragraph h1, h2, h3, h4, h5, h6, p {
  font-size: 1em;
}
h1, h2, h3, h4, h5, h6, font {
  color: inherit;
  font-size: inherit;
}
</style>
