export default {
  SET_SELECTED_PRODUCT(state, id) {
    state.selectedProduct = state.reyestrItems.find((shoe) => +shoe.id === +id)
    state.selectedProduct.colors.forEach((color) => {
      color.sizes.forEach((p) => (p.count = 0))
    })
  },
  SET_SELECTED_GROUP(state, val) {
    state.selectedGroup = val
  },
  SET_REYESTR_ITEMS(state, items) {
    state.reyestrItems = items
  },
  SET_SELECTED_REYESTR_ITEM(state, item) {
    state.selectedReyestrItem = item
  },
  SET_PRODUCT_CATEGORIES(state, categories) {
    state.productCategory = categories
  },
}
