<template>
  <div
    class="banner__container d-flex flex-column justify-content-center"
    :style="{
      backgroundImage: `url('${'https://admin.tumar.com/' + props.src}')`,
    }"
  >
    <div class="banner__content" :style="{ alignSelf: props.alignSelf }">
      <p v-if="props.text">
        {{ props.text }}
      </p>
      <a
        v-if="props.buttonHref"
        :style="{
          backgroundColor: props.buttonColor,
          color: props.buttonTextColor,
        }"
        :href="props.buttonHref"
      >
        get yours
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    props: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style lang="scss" scoped>
.banner {
  &__container {
    width: 100%;
    height: 200px;
    background-size: cover;
  }
  &__content {
    width: fit-content;
  }
}
</style>
