import Vue from 'vue'
import App from './App.vue'
import router from '@/core/services/router'
import store from '@/core/services/store'

Vue.config.productionTip = false

// Global 3rd party plugins
import 'tooltip.js'

// Vue 3rd party plugins
import i18n from '@/core/plugins/vue-i18n'
import vuetify from '@/core/plugins/vuetify'
import '@/core/plugins/portal-vue'
import '@/core/plugins/bootstrap-vue'
import '@mdi/font/css/materialdesignicons.css'
import VueMeta from 'vue-meta'
// Require dependencies
import VueCookie from 'vue-cookie'
// Tell Vue to use the plugin

import VueLazyload from 'vue-lazyload'
import loader from '../public/img/loader.gif'
import InfiniteScroll from 'vue-infinite-scroll'
import InfiniteLoading from 'vue-infinite-loading'

Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: loader,
  attempt: 1,
})
Vue.component('infinite-loading', InfiniteLoading)
Vue.use(VueCookie)

import VueMasonry from 'vue-masonry-css'
Vue.use(VueMasonry)

import vGoogleTranslate from 'v-google-translate'
Vue.use(vGoogleTranslate)

import recursion from './view/pages/store/recursion.vue'
router.afterEach(() => {
  store.dispatch('cart/addSavedCartItems', [])
})

// Infinite scroll

Vue.use(InfiniteScroll)

Vue.use(VueMeta)
Vue.component('recursion', recursion)
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
