<template>
  <main class="FAQ">
    <div class="container">
      <div class="tabs d-flex row ma-0">
        <div
          class="tabs__links col-4 pa-0"
          :style="$vuetify.breakpoint.smAndDown ? 'overflow: auto;' : ''"
        >
          <div
            :style="$vuetify.breakpoint.smAndDown ? 'min-width: 125px;' : ''"
            class="tab__link"
            :class="{
              active: tab === 0,
              'active-mobile': $vuetify.breakpoint.smAndDown && tab === 0,
              'd-flex justify-center': $vuetify.breakpoint.smAndDown,
            }"
            @click="tab = 0"
          >
            <div class="tabs__links-img">
              <img
                src="/img/productInfo.png"
                alt="productInfo.png"
                width="68"
                height="25"
              />
            </div>
            <p>Product<br />information</p>
          </div>
          <div
            :style="$vuetify.breakpoint.smAndDown ? 'min-width: 125px;' : ''"
            class="tab__link"
            :class="{
              active: tab === 1,
              'active-mobile': $vuetify.breakpoint.smAndDown && tab === 1,
              'd-flex justify-center': $vuetify.breakpoint.smAndDown,
            }"
            @click="tab = 1"
          >
            <div class="tabs__links-img">
              <img
                src="/img/checkout.png"
                alt="checkout.png"
                width="81"
                height="51"
              />
            </div>
            <p>Checkout</p>
          </div>
          <div
            :style="$vuetify.breakpoint.smAndDown ? 'min-width: 125px;' : ''"
            class="tab__link"
            :class="{
              active: tab === 2,
              'active-mobile': $vuetify.breakpoint.smAndDown && tab === 2,
              'd-flex justify-center': $vuetify.breakpoint.smAndDown,
            }"
            @click="tab = 2"
          >
            <div class="tabs__links-img">
              <img
                src="/img/delivery.png"
                alt="delivery.png"
                width="67"
                height="61"
              />
            </div>
            <p>Delivery</p>
          </div>
          <div
            :style="$vuetify.breakpoint.smAndDown ? 'min-width: 125px;' : ''"
            class="tab__link"
            :class="{
              active: tab === 3,
              'active-mobile': $vuetify.breakpoint.smAndDown && tab === 3,
              'd-flex justify-center': $vuetify.breakpoint.smAndDown,
            }"
            @click="tab = 3"
          >
            <div class="tabs__links-img">
              <img
                src="/img/return.png"
                alt="return.png"
                width="40"
                height="51"
              />
            </div>
            <p>Return<br />policy</p>
          </div>
          <div
            :style="$vuetify.breakpoint.smAndDown ? 'min-width: 125px;' : ''"
            :class="{
              'active active--contact': tab === 4,
              'd-flex justify-center mr-0 px-3': $vuetify.breakpoint.smAndDown,
            }"
            @click="tab = 4"
          >
            <div
              class="tabs__links-img"
              :class="{ 'd-none': $vuetify.breakpoint.smAndDown }"
            ></div>
            <p class="ma-0">Contact us</p>
          </div>
        </div>

        <div class="col-8 tabs__kontent">
          <template v-if="children.length">
            <recursion
              v-for="(ch, index) in children"
              :key="index"
              :content="ch"
              :componentName="
                ch.props.component ? ch.props.component : ch.componentName
              "
            />
          </template>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'FAQComponent',
  provide() {
    return {
      tab: () => this.activeTab,
    }
  },
  props: {
    props: {
      type: Object,
      default: () => {},
    },
    children: {
      type: Array,
      default: () => [],
    },
    content: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tab: 0,
      expansion: null,
    }
  },
  computed: {
    isFormInvalid() {
      const { name, email, subject, message } = this.contactUs
      return !name || !email || !subject || !message
    },
    activeTab() {
      return this.tab
    },
  },
  methods: {
    sendForm() {
      // send form
    },
  },
}
</script>

<style scoped lang="scss">
.v-expansion-panel--active > .v-expansion-panel-header {
  h2 {
    transition: 0.4s;
    color: #423f3d;
  }
}
.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: unset;
}
.tabs__links .active {
  background-color: #f8f8f8;
  position: relative;
  border-color: #f2f2f2;
  margin-right: 30px;
  &:after {
    content: '';
    position: absolute;
    top: -1px;
    right: 0;
    transform: translateX(100%);
    bottom: -1px;
    width: 32px;
    background-color: #f8f8f8;
    border-width: 1px 0 1px 0;
    border-style: solid;
    border-color: #f2f2f2;
    z-index: 1;
  }
  &.active--contact:after,
  &.active-mobile:after {
    content: none;
  }
}
.tab__link {
  border: 1px solid transparent;
  cursor: pointer;
}
.form-wrapper {
  background-color: #fff;
}
.accordion-content p {
  font-size: 12px;
}
.FAQ__subtitle {
  span {
    color: rgba(173, 173, 173, 1);
  }
}
.border-bottom {
  border-bottom: 1px solid #f2f2f2;
}

.tabs__kontent {
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  background: #f8f8f8;
  padding: 36px 73px 35px 38px;
  flex: 1;
}
.tabs__kontent img {
  width: 100%;
}

.tabs__kontent > .form {
  margin-bottom: 51px;
}

.tabs__kontent.form-wrapper {
  padding: 43px 53px;
}
</style>
