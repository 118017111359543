import axios from '@/core/services/api.service'

export default {
  fetchBanners({}, per_page = 22) {
    const url = `frontend/banners?per_page=22`
    return axios.post(url).then((res) => {
      const { data } = res
      return data
    })
  },
  async fetchAllBanners({ dispatch, commit }, per_page) {
    const { data } = await dispatch('fetchBanners', per_page)
    commit('SET_BANNERS', data)
  },
}
