export default {
  SET_PRODUCTS(state, products) {
    state.products = []
    state.products = products.data
    state.productPagination = products
  },
  SET_ALL_PRODUCTS(state, products) {
    state.allProducts = products
  },
  SET_MAIN_PRODUCTS(state, products) {
    state.mainProducts = products
  },
  SET_PRODUCT(state, product) {
    state.productDetails = {}
    state.productDetails = {
      ...product,
      product: {
        ...product?.product,
        v_craft:
          product?.product?.v_craft?.ru 
            ? JSON.parse(product?.product?.v_craft?.ru?.content)
            : '',
      },
    }
  },
  CLEAN_PRODUCTS(state) {
    state.products = []
  },
  CLEAN_PRODUCT_SLUG(state) {
    state.productDetails = {}
  },
  SET_REVIEWS(state, reviews) {
    state.reviews = reviews
  }
}
