// USA
export const locale = {
  TRANSLATOR: {
    SELECT: 'Select your language',
  },
  MENU: {
    NEW: 'new',
    ACTIONS: 'Actions',
    CREATE_POST: 'Create New Post',
    PAGES: 'Pages',
    FEATURES: 'Features',
    APPS: 'Apps',
    DASHBOARD: 'Dashboard',
  },
  REYESTR_MENU: [
    {
      title: 'Main',
      icon: '/img/icons/Home.svg',
      to: 'Admin home',
      disabled: false,
    },
    {
      title: 'Profile',
      icon: '/img/icons/Document.svg',
      to: 'profile',
      disabled: false,
    },
    {
      title: 'Order history',
      icon: '/img/icons/Folder.svg',
      disabled: false,
      to: 'order history',
      subLinks: [],
    },
    {
      title: 'Order history retail',
      icon: '/img/icons/Folder.svg',
      disabled: false,
      to: 'order history retail',
      subLinks: [],
    },
    {
      title: 'Invoice',
      icon: '/img/icons/Paper.svg',
      to: 'invoice',
      disabled: false,
    },
    {
      title: 'Messages',
      icon: '/img/icons/Group.svg',
      to: 'chats',
      disabled: false,
    },
    {
      title: 'Price list',
      icon: '/img/icons/Group.svg',
      to: 'price list',
      disabled: false,
    },
    {
      title: 'Reyestr',
      icon: '/img/icons/Group.svg',
      to: 'reyestr',
      disabled: false,
    },
  ],
  RETAIL_MENU: [
    {
      title: 'Messages',
      icon: '/img/icons/Group.svg',
      to: 'chats',
      disabled: false,
    },
    {
      title: 'Profile',
      icon: '/img/icons/Document.svg',
      to: 'profile',
      disabled: false,
    },
    {
      title: 'Order history retail',
      icon: '/img/icons/Folder.svg',
      disabled: false,
      to: 'order history retail',
      subLinks: [],
    },
  ],
  SEARCH: {
    SEARCH_KEYWORDS: 'Search keywords...',
  },
  GROUPS: 'Groups',
  PRICE_TITLE: 'Price',
  SELE_CTED_GROUP: 'All',
  DROPDOWN: {
    items: ['All', 'Male', 'Female', 'Children', 'Unisex'],
  },
  FILTERS: {
    groups: {
      male: false,
      female: false,
      children: false,
      unisex: false,
    },
    'production technique': {
      'solid-rolled': false,
      stitched: false,
    },
    model: {
      slippers: false,
      boots: false,
    },
  },
  FILTER_KEYS: {
    groups: 'groups',
    production_technique: 'тproduction technique',
    model: 'model',
  },
  AUTH: {
    GENERAL: {
      OR: 'Or',
      SUBMIT_BUTTON: 'Submit',
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: 'Sign Up',
      FORGOT_BUTTON: 'Forgot Password',
      BACK_BUTTON: 'Back',
      PRIVACY: 'Privacy',
      LEGAL: 'Legal',
      CONTACT: 'Contact',
    },
    LOGIN: {
      TITLE: 'Login Account',
      BUTTON: 'Sign In',
    },
    FORGOT: {
      TITLE: 'Forgot Password?',
      DESC: 'Enter your email to reset your password',
      SUCCESS: 'Your account has been successfully reset.',
    },
    REGISTER: {
      TITLE: 'Sign Up',
      DESC: 'Enter your details to create your account',
      SUCCESS: 'Your account has been successfuly registered.',
    },

    LOGOUT: 'Log out',
    INPUT: {
      EMAIL: 'Email',
      FULLNAME: 'Fullname',
      PASSWORD: 'Password',
      CONFIRM_PASSWORD: 'Confirm Password',
      USERNAME: 'Username',
    },
    VALIDATION: {
      INVALID: '{{name}} is not valid',
      REQUIRED: '{{name}} is required',
      MIN_LENGTH: '{{name}} minimum length is {{min}}',
      AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
      NOT_FOUND: 'The requested {{name}} is not found',
      INVALID_LOGIN: 'The login detail is incorrect',
      REQUIRED_FIELD: 'Required field',
      MIN_LENGTH_FIELD: 'Minimum field length:',
      MAX_LENGTH_FIELD: 'Maximum field length:',
      INVALID_FIELD: 'Field is not valid',
    },
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: 'Selected records count: ',
      ALL: 'All',
      SUSPENDED: 'Suspended',
      ACTIVE: 'Active',
      FILTER: 'Filter',
      BY_STATUS: 'by Status',
      BY_TYPE: 'by Type',
      BUSINESS: 'Business',
      INDIVIDUAL: 'Individual',
      SEARCH: 'Search',
      IN_ALL_FIELDS: 'in all fields',
    },
    ECOMMERCE: 'eCommerce',
    CUSTOMERS: {
      CUSTOMERS: 'Customers',
      CUSTOMERS_LIST: 'Customers list',
      NEW_CUSTOMER: 'New Customer',
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: 'Customer Delete',
        DESCRIPTION: 'Are you sure to permanently delete this customer?',
        WAIT_DESCRIPTION: 'Customer is deleting...',
        MESSAGE: 'Customer has been deleted',
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: 'Customers Delete',
        DESCRIPTION: 'Are you sure to permanently delete selected customers?',
        WAIT_DESCRIPTION: 'Customers are deleting...',
        MESSAGE: 'Selected customers have been deleted',
      },
      UPDATE_STATUS: {
        TITLE: 'Status has been updated for selected customers',
        MESSAGE: 'Selected customers status have successfully been updated',
      },
      EDIT: {
        UPDATE_MESSAGE: 'Customer has been updated',
        ADD_MESSAGE: 'Customer has been created',
      },
    },
  },
  HEADER: {
    GO_TO_STORE: 'GO TO THE STORE',
    CON_TACTS: 'Contacts',
  },
  MAIN: {
    h1: 'Welcome',
    tumar: 'Tumar',
    logout: 'Log out',
    info: {
      1: 'From your account dashboard you can view your',
      2: 'recent orders',
      3: 'manage your',
      4: 'shipping and billing addresses',
      5: 'and',
      6: 'edit your password and account details.',
    },
  },
  PROFILE: {
    balance: 'Your balance',
    order_details: 'Order Details',
    settings_title: 'Settings',
    user_title: 'User',
    edit_user: 'Edit User',
    user_form: [
      'First name',
      'Last name',
      'Disaply name',
      'Email address',
      'Role',
      'Password'
    ],
    change_password: 'Password change',
    password_change_form: [
      'Current password (leave blank to leave unchanged)',
      'New password (leave blank to leave unchanged)',
      'Confirm new password',
    ],
    save_password: 'Save changes',
    add: 'Add',
    delete: 'delete',
    edit: 'edit',
    user: ['User', 'E-mail adress', 'Role', 'Actions', ''],
  },
  INVOICE: {
    order_number: 'Order number',
    invoice_table: ['Invoice number', 'Date', 'Status', 'Total sum', 'Invoice sum', 'Actions'],
    view: 'View',
    status_value: 'In progress',
  },
  BRENDING: {
    price: 'Price',
    choosePrice: 'Choose your price',
    brending_text: 'Brending',
    pre_count: 'Pre-count of selected items',
    colors: 'Colors',
    add_to_cart: 'Add to cart',
    size: 'Size',
    brending_body: {
      place: 'Leave',
      price_unchangable: 'Price is not changable',
      price_changable: 'Price changable',
      replace: 'Replace',
      delete: 'Delete',
    },
  },
  PRODUCT_DESCRIPTION: {
    branding: 'Branding',
    table: {
      image: 'image',
      details: 'details',
      price: 'price',
      color: 'color',
      size: 'size',
      count: 'count',
      total: 'total',
      pre_count: 'pre-count',
      order_details: 'order details',
    },
    second_table: {
      totalCount: 'total count',
      sum: 'sum',
      discount: 'discount',
      saving: 'saving',
      delivery: 'delivery',
      total: 'total',
      saved: 'saved',
    },
    go_to_order: 'go to order',
    notes:
      'Details of applying the logo on your product will be provided later by chat Manager',
    logo_place: 'Place for logo',
    order_details: 'Order detail info',
  },
  REGISTRATION: {
    my_order: 'My order',
    payment_info: 'Payment information',
    first_name: 'First name',
    last_name: 'Last name',
    number: 'Number',
    email: 'Email adress',
    delivery_adress: 'Enter delivery address',
    country: 'Country',
    countries: ['Russia', 'Ukraine', 'Kyrgyzstan', 'Kazakhstan', 'Belarus'],
    address: 'Address',
    home_number: 'Home number and name of the street',
    locality: 'locality',
    region: 'region',
    postcode: 'postcode',
    inn: 'INN',
    your_order: 'Your order',
    coupon_code: 'IF YOU HAVE A COUPON CODE PLEASE APPLY IT BELOW',
    coupon_placeholder: 'Have a promocode / dicount card number?',
    apply_coupon: 'APPLY COUPON',
    product: 'Product',
    subtotal: 'Subtotal',
    shipping: 'Shipping',
    total: 'Total',
    rule:
      'By placing an order, you accept our [get_rules], [get_politica_konf] and [get_return_and_exchange]. You also agree that TUMAR will store some of your data in order to improve the quality of your service the next time you shop on our site.',
    place_order: 'Place order',
  },
  ORDER_HISTORY: {
    order: 'Order',
    ordered: 'Ordered',
    shipped: 'Shipped',
    expected_delivery: 'Expected Delivery',
    order_number: 'Order number',
    date: 'Date',
    total: 'Total',
    order_info: 'Order information',
    invoice: 'INVOICE',
    retry: 'RETRY',
    payment_terminal: 'Payment Terminal',
    delivery_address: 'Delivery Address',
    order_table: ['Order', 'Date', 'Status', 'Total', 'Actions'],
    statuses: [
      { value: 'Processing', isActive: false },
      { value: 'Awaiting Payment', isActive: false },
      { value: 'In Production', isActive: false },
      { value: 'On Hold', isActive: false },
      { value: 'Completed', isActive: false },
      { value: 'Cancelled', isActive: false },
      { value: 'Returned', isActive: false },
      { value: 'Failed', isActive: false },
      { value: 'Packaging', isActive: false },
      { value: 'Shipped', isActive: false },
      { value: 'In Transit', isActive: false },
    ],
  },
  ORDER_COMPLEATE: {
    title: 'Go to order history',
    thanksTitle: 'Thanks. Your order has been accept.',
  },
  PRICE_LIST: {
    price: 'Price list',
    main: 'Main',
  },
  CART_FOOTER: {
    view: 'View Basket',
    ordering: 'Ordering',
    summary: 'Summary',
    basket: 'Basket',
    close: 'close',
  },
  ORDER_DETAILS: {
    account: 'My account',
    order_details: 'Order details',
    title: 'ORDER DETAILS',
    table: ['Photo', 'Title', 'remainder', 'Actions'],
    order_details_table: [
      '№',
      'Date',
      'Product Title',
      'Amount of branding consumed',
      'Current balance',
    ],
  },
}
