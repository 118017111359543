export default {
  SET_RETAIL_CATEGORIES(state, retailCategories) {
    state.retailCategories = retailCategories
  },
  SET_DEFAULT_CATEGORY(state, category) {
    state.defaultCategory = category
  },
  SET_CATEGORY(state, category) {
    state.category = category
  },
  CLEAN_SELECTED_CATEGORY(state) {
    state.category = {}
    state.defaultCategory = {}
  },
}
