var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
    marginTop: _vm.props.marginTopValue + 'px',
    marginBottom: _vm.props.marginBottomValue + 'px',
    marginLeft: _vm.props.marginLeftValue + 'px',
    marginRight: _vm.props.marginRightValue + 'px',
  })},[_c('div',{staticClass:"imageContaner",style:({ width: _vm.props.width, height: _vm.props.height })},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('https://admin.tumar.com/' + _vm.props.src),expression:"'https://admin.tumar.com/' + props.src"}],attrs:{"alt":"image"}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.props.description || _vm.props.href),expression:"props.description || props.href"}],staticClass:"d-flex flex-column align-center"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.props.description),expression:"props.description"}],staticClass:"text-center mb-3",domProps:{"innerHTML":_vm._s(_vm.props.description)}}),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.props.buttonText),expression:"props.buttonText"}],staticClass:"px-10 py-2",style:({
        color: _vm.props.buttonTextColor,
        backgroundColor: _vm.props.buttonColor,
      }),attrs:{"href":_vm.props.href}},[_vm._v(" "+_vm._s(_vm.props.buttonText)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }