export default {
  SET_AUTH(state) {
    state.isAuthenticated = true
  },
  SET_USER(state, user) {
    state.user = user
  },
  SET_LOCALE_LANG(state, lang) {
    state.localeLang = lang
  },
  SET_LOCALES(state, langs) {
    state.locales = langs
  },
}
