import axios from '@/core/services/api.service'

export const actions = {
  async fetchMenus({ commit, rootState }) {
    const localeLang = rootState.auth?.localeLang

    const api = `frontend/menu?locale=${localeLang}`
    return axios.post(api).then((res) => {
      commit('SET_MENUS', res)
    })
  },
}
