import axios from '@/core/services/api.service'

export const actions = {
  fetchBlogCategories({ commit, rootState }) {
    const api = `frontend/blog-category?locale=${rootState.auth.localeLang}`
    return axios.get(api).then((res) => {
      commit('SET_BLOG_CATEGORIES', res.data)
    })
  },
  fetchBlogCategoryByUrl({ commit, rootState }, url) {
    const api = `frontend/blog-category/${url}?locale=${rootState.auth.localeLang}`
    return axios.get(api).then((res) => {
      return res.data
    })
  },
}
