export const mutations = {
  SET_MENUS(state, payload) {
    state.retail = Object.values(payload.data.retail)?.map((nestedObj) => {
      return Object.entries(nestedObj).reduce((acc, [key, value]) => {
        acc[key] = value
        return acc
      }, {})
    })
    state.wholesale = Object.values(payload.data.wholesale)?.map(
      (nestedObj) => {
        return Object.entries(nestedObj).reduce((acc, [key, value]) => {
          acc[key] = value
          return acc
        }, {})
      }
    )

    function extractUrls(objArray) {
      const result = []

      function extractUrlsRecursive(obj, depth) {
        if (obj.url && obj.url !== '#') {
          result.push(obj.url)
        }

        if (obj.childrens && obj.childrens.length > 0) {
          for (const child of obj.childrens) {
            extractUrlsRecursive(child, depth + 1)
          }
        }
      }

      for (const obj of objArray) {
        extractUrlsRecursive(obj, 0)
      }

      return result
    }

    state.retailUrl = extractUrls(state.retail)
    state.wholesaleUrl = extractUrls(state.wholesale)
    state.wholesaleUrl = state.wholesaleUrl.filter((el) => el !== '/shop')
  },
}
