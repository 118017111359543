export default {
  SET_PRICE_LIST(state, list) {
    state.priceList = list
  },
  SET_CATALOG(state, catalog) {
    state.catalog = catalog
  },
  SET_SELECTED_ITEM(state, item) {
    state.selectedPriceListItem = item
  },
}
