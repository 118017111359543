<template>
  <div class="video-container">
    <iframe
      v-if="props.src.includes('youtube')"
      :style="{ width: isDesktop ? props.width : '100%', height: isDesktop ? props.height : 'auto', objectFit: 'cover' }"
      :src="props.src"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
    <video v-else-if="props.poster"    
      :style="{ width: isDesktop ? props.width : '100%', height: isDesktop ? props.height : 'auto', objectFit: 'cover' }"
      controls
      preload="metadata"
      :poster="props.poster"
    >
      <source :src="'https://admin.tumar.com/' + props.src"  />
    </video>
    <video v-else    
      :style="{ width: isDesktop ? props.width : '100%', height: isDesktop ? props.height : 'auto', objectFit: 'cover' }"
      controls
      preload="metadata"
    >
      <source :src="'https://admin.tumar.com' + props.src"  />
    </video>
  </div>
</template>

<script>
export default {
  props: {
    props: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isDesktop() {
      if(window.innerWidth > 780) {
        return true
      }else {
        return false
      }
    }, 
  }
}
</script>

<style>
  .video-container {
    width: 100%
  }
  video {
    object-fit: cover;
    height: 100%;
  }
</style>
