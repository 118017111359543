<template>
  <a
    class="button py-3 px-10 d-block"
    :style="{
      backgroundColor: props.backgroundColor,
      color: props.textColor,
      alignSelf: props.position
    }"
    :href="props.href"
  >
    {{ props.text }}
  </a>
</template>

<script>
export default {
  name: 'BuilderButton',
  props: {
    props: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style scoped>

</style>
