import feltShoes from '@/core/mock/feltShoes.json'
import interiorItems from '@/core/mock/interiorItems.json'
import accessories from '@/core/mock/accessories.json'
import collections from '@/core/mock/collections.json'
import blogs from '@/core/mock/blogs.json'
import bestSellers from '@/core/mock/bestSellers.json'
import recommendedItems from '@/core/mock/recommendedItems.json'
import colors from '@/core/mock/colors.json'

export default {
  feltShoes,
  interiorItems,
  accessories,
  collections,
  blogs,
  bestSellers,
  recommendedItems,
  colors,
}
