import axios from '@/core/services/api.service'

export default {
  getAllRetailCategories({ commit }) {
    return axios.get('/frontend/retail/category').then((res) => {
      commit('SET_RETAIL_CATEGORIES', Object.values(res.data))
      commit('SET_DEFAULT_CATEGORY', Object.values(res.data)[0])
    })
  },
  getRetailCategoryBySlug({ commit }, slug) {
    return axios.get(`/frontend/retail/category/${slug}`).then((res) => {
      commit('SET_CATEGORY', res.data)
    })
  },
}
