<template>
  <ul class="production__gallery pa-0 container">
    <li class="row ma-0">
      <div class="col col-4 pa-1">
        <div class="production__gallery-img">
          <img :src="'https://admin.tumar.com/' + props.images.img1" alt="" />
        </div>
      </div>
      <div class="col col-8 pa-1">
        <div class="production__gallery-img">
          <img :src="'https://admin.tumar.com/' + props.images.img2" alt="" />
        </div>
      </div>
    </li>
    <li class="row ma-0">
      <div class="col col-8 pa-1">
        <div class="production__gallery-img">
          <img :src="'https://admin.tumar.com/' + props.images.img3" alt="" />
        </div>
      </div>
      <div class="col col-4 pa-1">
        <div class="production__gallery-img">
          <img :src="'https://admin.tumar.com/' + props.images.img4" alt="" />
        </div>
      </div>
    </li>
    <li class="row ma-0">
      <div class="col col-4 pa-1">
        <div class="production__gallery-img">
          <img :src="'https://admin.tumar.com/' + props.images.img5" alt="" />
        </div>
      </div>
      <div class="col col-4 pa-1">
        <div class="production__gallery-img">
          <img v-lazy="'https://admin.tumar.com/' + props.images.img6" alt="" />
        </div>
      </div>
      <div class="col col-4 pa-1">
        <div class="production__gallery-img">
          <img v-lazy="'https://admin.tumar.com/' + props.images.img7" alt="" />
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Images',
  props: {
    props: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style scoped lang="scss">
.production__gallery {
  display: flex;
  flex-direction: column;
  li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1px;
  }
  &-title {
    margin: 53px 0;
    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      color: #666666;
      margin-bottom: 12px;
    }
    p {
      width: 293px;
      margin: 0 auto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 25px;
      text-align: center;
      color: #666666;
    }
  }
  &-img {
    height: 250px;
    width: 100%;
    background-color: #cfebcc;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}
</style>
