export default {
  addAlert({ commit }, { type, text, timeout = 1500 }) {
    const id = Date.now()
    commit('SET_ALERT', {
      type,
      text,
      id,
    })
    setTimeout(() => {
      commit('REMOVE_ALERT', id)
    }, timeout)
  },
}
