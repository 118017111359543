export default {
  getProductById: (state) => (id) => {
    const items = [
      ...state.feltShoes,
      ...state.interiorItems,
      ...state.bestSellers,
      ...state.recommendedItems,
    ]
    const product = items.find((item) => item.id == id)
    return product
  },
}
