import axios from '@/core/services/api.service'

export default {
  fetchPageByUrl({ commit, rootState }, url) {
    const localeLang = rootState.auth.localeLang

    const apiUrl = `frontend/pages/${url}?locale=${localeLang}`
    return axios.get(apiUrl).then((res) => {
      commit('SET_CURRENT_PAGE', res.data)
    })
  },
}
