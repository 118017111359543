import Vue from 'vue'
import Router from 'vue-router'
import goTo from 'vuetify/lib/services/goto'
import store from '@/core/services/store'
import adminRoutes from './adminRoutes'
import storeRoutes from './storeRoutes'

Vue.use(Router)
const router = new Router({
  routes: [
    ...adminRoutes,
    ...storeRoutes,
    {
      path: '*',
      name: '404',
      component: () => import('@/view/pages/error/Error-1.vue'),
      meta: {
        title: 'Page not found',
      },
    },
  ],
  mode: 'history',
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((route) => route.meta.requiresAuth)
  if (requiresAuth && !store.getters['auth/isAuthenticated']) {
    return next({ name: 'login' })
  }
  goTo(0, { duration: 0 })
  document.title = to.meta.title
  next()
})
export default router
