import JwtService from '@/core/services/jwt.service'

export default {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  localeLang: '',
  locales: [],
  // API_URL: 'http://127.0.0.1:8000',
  API_URL: 'https://admin.tumar.com',
}
