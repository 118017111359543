export default {
  toggleSideCart({ commit }, value) {
    commit('TOGGLE_SIDE_CART', value)
  },
  addCartItem({ commit }, item) {
    commit('ADD_CART_ITEM', item)
  },
  removeCartItem({ commit }, id) {
    commit('REMOVE_CART_ITEM', id)
  },
  increaseCartItemCount({ commit }, cartId) {
    commit('CHANGE_CART_ITEM_COUNT', { cartId, isIncrement: true })
  },
  decreaseCartItemCount({ commit }, cartId) {
    commit('CHANGE_CART_ITEM_COUNT', { cartId, isIncrement: false })
  },
  setSelectedProduct({ commit }, product) {
    commit('CLEAN_PRODUCT')
    commit('SET_SELECTED_PRODUCT', product)
  },
}
