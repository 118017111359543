const requiredModules = require.context('.', true, /index.js$/)
import camelCase from 'lodash/camelCase'
const modules = {}
const exclusions = ['./index.js']
requiredModules.keys().forEach((module) => {
  if (exclusions.includes(module)) return
  const moduleName = camelCase(module.replace(/(\.\/|index\.js)/g, ''))
  modules[moduleName] = requiredModules(module).default
})
export default modules
