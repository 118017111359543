<template>
  <v-expansion-panel class="aboutUs__accordion-item">
    <v-expansion-panel-header hide-actions class="accordion-btn">
      <span
        class="accordion-icon"
        :style="
          index === expansion
            ? 'background-image: url(/img/icons/minus-icon.svg)'
            : 'background-image: url(/img/icons/plus-icon.svg)'
        "
      ></span>
      <h2>{{ title }}</h2>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="accordion-content">
      <template v-if="children.length">
        <recursion
          v-for="(ch, index) in children"
          :key="index"
          :content="ch"
          :componentName="
            ch.props.component ? ch.props.component : ch.componentName
          "
        />
      </template>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: 'AccordionItem',
  props: {
    title: {
      type: String,
    },
    index: {
      type: Number,
    },
    expansion: {
      type: Number,
    },
    children: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style scoped>
.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: unset !important;
}
.accordion-btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 17px 0px;
  cursor: pointer;
}
.accordion-btn h2 {
  width: 80%;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #666666;
  transition: background-color 0.2s linear;
  margin: 0;
}
.accordion-icon {
  background-repeat: no-repeat;
  width: 23px;
  height: 23px;
  display: inline-block;
  margin-right: 16px;
  position: relative;
}
</style>
