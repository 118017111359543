export default {
  ADD_NOTIFICATION(state, payload) {
    const existingProduct = state.notifications.find(
      (item) => item.productId === payload.productId
    )
    if (!existingProduct) {
      state.notifications.unshift(payload)
    } else {
      const sameProductArray = state.notifications.filter(
        (item) => item.productId === payload.productId
      )
      if (payload.priceType === 'sole') {
        const existingSole = sameProductArray.find(
          (item) =>
            item.soleId === payload.soleId && item.colorId === payload.colorId
        )
        if (existingSole) {
          existingSole.saving = payload.saving
        } else {
          state.notifications.unshift(payload)
        }
      } else {
        const existingColor = sameProductArray.find(
          (item) => item.colorId === payload.colorId
        )
        if (existingColor) {
          existingColor.saving = payload.saving
        } else {
          state.notifications.unshift(payload)
        }
      }
    }
  },
  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload
  },
}
